import styled, { css } from 'styled-components';

const ImageContainer = styled.div`
  width: ${({ active }) => active ? '92px' : '50px'};
  height: ${({ active }) => active ? '92px' : '50px'};
  border-radius: ${({ active }) => active ? '50%' : '25px'};
  border: 3px solid #FFFFFF;
  background-color: #e8e7e7;
  position: relative;
  overflow: hidden;
  float: left;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  > img {
    width: 100%;
    margin-top: 0px;
    object-fit: cover;
  }
`;

const EmployeeName = styled.span`
  width: 100%;
  font-size: ${({ active }) => active ? '22px' : '16px'};
  font-family: Rubik-Medium;
  font-style: normal;
   font-weight: 500;
   line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ color }) => color ? color : ' #3A3A3A'};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  text-overflow: ellipsis;
  position: relative;
  bottom: 15px;
`;

const Row = styled.div`
  padding: 10px 0;
  width: 100%;
  float: left;
  border-bottom: 1px solid #e3e3e3;
  cursor: ${({disableCursor}) => disableCursor ? 'default' : 'pointer'};
  text-align: ${({align}) => align && 'center'};
  border-top: ${({ upperBoxShadow }) => upperBoxShadow && upperBoxShadow };
  ${({ active }) => active && css`
    background-color: #f0f6f8;
  `}

  background-color: ${({deleted}) => deleted && '#ffc8bf'};

  @media (max-width: 600px) {
    &:first-child {
      border-top: 1px solid #e3e3e3;
    }
  }
`;

export { ImageContainer, EmployeeName, Row };
