import styled, { css } from 'styled-components';
const MediumFont = 'Rubik-Regular';
const BoldFont = 'Rubik-Medium';

const HeaderSection = styled.div`
  width: 100%;
  padding: 10px 0;
  float: left;
  display:flex;

  @media (max-width: 750px) {
    display: none;
  }
`;

const Layout = styled.div`
  width: 100%;
  margin-bottom: 60px;
  font-family: ${MediumFont};
  padding: 25px;

  @media (max-width: 500px) {
    padding: 30px 0 0;
  }
  .scrollGrid {
    float: left;
    width: 100%;
  }

  .inactiveUsersGrid{
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }

  .notScroll{
    flex-wrap: wrap;
    display: flex;
    gap: 15px 24px;
  }

  .margin-top-25{
    margin-tpo: 25px;
  }
`;

const NameSection = styled.div`
 position: ${({header}) => header ? 'relative' : 'none'};
  width: 65%;
  text-align: left;
  display: inline-block;
  float: left;
  font-family: ${({fontFamily}) =>fontFamily ? fontFamily: `${MediumFont}` };
  font-size: ${({fontSize}) =>fontSize ? fontSize: 'none' }
  color:  ${({color}) =>color? color: 'rgba(40,44,55,0.5)' }
  left: ${({header}) => header ? '106px' : 'none'};
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0
    
    @media (max-width: 500px) {
      display: block;
      float: none;
      margin: 0 auto;
    }
  }
  

  @media (max-width: 750px) {
    width: 100%;
    padding-left: 0;
    > div {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  ${({ header }) => header && css`
    float: none;
    font-size: 15px;
  `}
`;

const NameSectionNew = styled.div`
  width: calc(36% + 85px);
  text-align: left;
  display: inline-block;
  float: left;
  font-family: ${({fontFamily}) =>fontFamily ? fontFamily: `${MediumFont}` };
  font-size: ${({fontSize}) =>fontSize ? fontSize: 'none' };
  color:  ${({color}) =>color? color: 'rgba(40,44,55,0.5)' };
  padding-left: 80px;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 7px 0
    padding-left: 80px;
    @media (max-width: 500px) {
      display: block;
      float: none;
      margin: 0 auto;
    }
  }
  

  @media (max-width: 750px) {
    width: 100%;
    padding-left: 0;
    > div {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  ${({ header }) => header && css`
    float: none;
    font-size: 15px;
  `}
`;


const OtherSections = styled.div`
  width: ${({interest}) => interest ? interest : '33%'};
  text-align: left;
  align-items:${({interest}) => interest ? 'left' : 'center'};
  display: inline-block;
  float: left;
  font-family: ${MediumFont};
  font-family: ${({fontFamily}) =>fontFamily ? fontFamily: `${MediumFont}` };
  font-size: ${({fontSize}) =>fontSize ? fontSize: '16px' };
  color:  ${({color}) =>color? color: 'rgba(40,44,55,0.5)' };
  padding-left: ${({paddingleft}) =>paddingleft ? '0px': '0px' };

  cursor: ${({cursor}) => cursor && 'pointer'};
  > i {
    margin-left: 5px;
    font-size: 10px;
  }

  font-size: 12px;
  color: ${(color) => color ? color : 'rgb(153,153,153)'};
  font-weight: 300;
  text-align: left;
  display: ${(props) => props.wrap ? 'inline-block' : 'flex'};
  -webkit-box-align: center;
  
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
  flex-wrap: wrap;
  word-break: break-word;
  word-wrap:break-all;
  align-items: center;

  @media (max-width: 750px) {
    width: 100%;
    height: auto;
    padding: 7px 0;
  }

  ${({ header }) => header && css`
    font-size: 15px;
    letter-spacing: 0px;
    color: rgba(40,44,55,0.5);
    font-family: ${MediumFont};
    float: none;
    line-height: initial;
    display: inline-block;
    height: auto;
  `}
`;

const ImageContainer = styled.div`
  min-width: ${({ active, width }) => width ? width : active ? '92px' : '52px'};
  height: ${({ active, width }) => width ? width : active ? '92px' : '52px'};;
  border-radius: ${({ active }) => active ? '50%' : '6px'};
  background-color: #e8e7e7;
  overflow: hidden;
  float: left;
  margin: 0 18px 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  > img {
    width: 52px;
    height: 52px;
    margin-top: 0px;
    object-fit: cover;
  }
  @media (max-width: 500px) {
    display: block;
    float: none;
    margin: 0 auto;
  }
`;

const EmployeeName = styled.span`
  font-size: ${({ active }) => active ? '16px' : '16px'};
  font-family:${({ active, inactive }) => inactive?"rubik": active ? 'Rubik-Medium' : 'Rubik-Medium'}; 
  letter-spacing: 0px;
  color: ${({ color }) => color ? color : 'rgb(51,51,51)'};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  padding-right: 10px;
  text-align: left;
  @media (max-width: 500px) {
    margin : 0 auto;
    text-align: center;
  }
`;

const Designation = styled.div`
    >p{
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #9C9C9C;
      mix-blend-mode: normal;
      margin:0px
    }
`;

const Row = styled.div`
  width: 828px;
  width: 100%;
  float: left;
   margin-bottom: 10px;
  cursor: ${({disableCursor}) => disableCursor ? 'default' : 'pointer'};
  text-align: ${({align}) => align && 'center'};
  border-top: ${({ upperBoxShadow }) => upperBoxShadow && upperBoxShadow };
  border-radius:  ${({borderRadius}) => borderRadius ? '4px' : '4px'};
  background-color: ${({newDeleted}) => newDeleted ? '#FFC0CB' :'#fff'};
  ${({ active }) => active && css`
    //background-color: rgba(71,152,217,0.1);
  `}
  ${({active}) => active && css`
  filter: drop-shadow(5px 4px 10px rgba(58, 58, 58, 0.2));
  border: 1px solid #0D4270;
`}

  @media (max-width: 600px) {
    &:first-child {
      border-top: 1px solid #e3e3e3;
    }
  }

  .challenge-user {
    width: 35%;
    float: left;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    @media (max-width: 660px) {
      position: static;
      margin: 10px 15px;
    }
  }
  
  .user-info {
    width: 50%;
    display: flex;
    margin-left: 0;
    margin: auto;
    justify-content: flex-start
    > h6 {
      color: #67676A;
      font-size: 15px
      font-family: ${MediumFont};
      opacity: 0.5
      margin-left: 5px;
      text-transform: none;
      margin-top: 0;
    }
    > span {
      margin-left: -12px;
      > img {
        width:30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
    .user-list {
      color: #67676A;
      font-size: 15px
      font-family: ${MediumFont};
      opacity: 0.5
      margin-left: 5px;
      text-transform: none;
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center
    }
    .circle {
      font-family: ${BoldFont}
      color: #fff;
      display: inline-block;
      padding-top: 3px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #fff;
      text-align: center;
      background-color: #4798D9;
    } 
  }
  
`;

const EmployeeInterestContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '15px'};
    left: ${({newPosition}) => newPosition ? '51px' : 'none'};
    bottom: ${({newPosition}) => newPosition ? '6px' : 'none'};
    & > div {
      width: 32px;
      height: 32px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      background: linear-gradient(360deg, rgb(30, 100, 129) 0%, rgb(13, 66, 92) 60.94%, rgb(2, 44, 67) 100%);
      ${({active}) => active && css`
      background: linear-gradient(360deg, rgb(30, 100, 129) 0%, rgb(13, 66, 92) 60.94%, rgb(2, 44, 67) 100%);
      `}
      background-color: ${({deleted, active}) => deleted && ' #F6F6F8' || !active && ' #F6F6F8'};
      & > img {
        width: 20px;
        height: 20px;
        border-radius: 6px;
      }
    }
    @media (max-width: 500px) {
        justify-content: center;
        padding: 7px 0;
    }
`;

const MutualHeading = styled.div`
margin: auto 45px auto 0px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
align-items: center;

color: #0D4270;
`;

const RowCard = styled.div`
width: 100%;
display: flex;
background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
margin-bottom: 10px;
border: ${({active}) => active ? 'none' : 'none'};
border-radius: 6px;
&:hover, &:focus, &:active {
  filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
  border: ${({deleted}) => deleted ? "1px solid #FD7175" : '1px solid rgba(0, 47, 71, 1)'};
}
.wrapper{
  width: 553px;
  width: 44.5%;
  display: flex;
.profile{
  width: 60px;
  height: 60px;
  margin: 10px 15px 10px 10px;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
.user{
  margin: auto 0px;
  .name{
    font-family: Rubik-Medium;
    font-size: 16px;
    line-height: 20px;
    color:#0D4270;
    display: flex;
    .wellness-champions{
      width: 164.17px;
      height: 16px;
      margin-left 5px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .wellness-champions-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/wellnessChampion.png");
      min-width: 164.17px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .remove-user-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/RemoveUser.png");
      width: 241px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  .department{
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color:#9c9c9c;
  }
}
}
.no-data{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#0D4270;
  margin: auto;
  display: flex;
  margin-left: 0px;
  width:70px;
  justify-content:center;
}
.profile-image-card{
  display: flex;
  margin: auto;
  margin-left: 12px;
  width:70px;
  justify-content:center;
  >img{
    width: 34px;
    height: 34px;
    margin: 10px 0px;
    border-radius: 50%;
    margin-left: -12px;
    border: 2px solid white;
  }
  .count{
    display: 100%;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    font-family: Rubik;
    font-size: 12px;
    color: #fff;
    padding: 0px 8px;
    height: 34px;
    margin: auto;
    border-radius: 15px;
    margin-left: -12px;
    align-items: center;
    text-align: center;
    display: flex;
    border: 2px solid white;
  }
}
.wellness-card{
  display: flex;
  justify-content: center;
  margin: auto;
  margin-left: 0px;
  min-width: 180px;
  justify-content: flex-start;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 80px; // 65px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }


  }
}
`;
 
const RowCardPeopleHeader = styled.div`
  width: 100%;
  display: flex;
  ${'' /* justify-content: space-between; */}
  align-items: center;
  background-color: #ffff;
  padding: 10px;
  margin-bottom: ${({adminview}) => adminview ? '20px' : '10px'};
  ${'' /* margin-bottom: 10px; */}
  border-radius:4px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  flex: 1;
  color:#005C87;
.userHeading {
  min-width:${({inactiveUser}) => inactiveUser ? '25%' : '30%'};
}
.departmentHeading {
  min-width:${({inactiveUser}) => inactiveUser ? '17%' : '20%'};
  ${'' /* min-width: 17%;  */}
}
.locationHeading {
  min-width:${({inactiveUser}) => inactiveUser ? '17%' : '20%'};
  ${'' /* min-width: 17%;  */}
}
.emailHeading {
  ${'' /* min-width: 20%;  */}
  min-width: ${({ isShowDepartment, isShowLocation }) => 
    isShowDepartment && isShowLocation ? '30%' : '26%'};
}
.reminderHeading {
  min-width: 15%; 
}
.interestHeading {
  min-width: 20%; 
}
.actionHeading {
  min-width: 10%; 
}
`;



const RowCardPeople = styled.div`
width: 100%;
display: flex;
background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
margin-bottom: 10px;
border: ${({active}) => active ? 'none' : 'none'};
border-radius: 6px;
&:hover, &:focus, &:active {
  filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
  border: ${({deleted}) => deleted ? "1px solid #FD7175" : '1px solid rgba(0, 47, 71, 1)'};
}
.wrapper{
  width: 553px;
  width: 35%;
  display: flex;
.profile{
  width: 60px;
  height: 60px;
  margin: 10px 15px 10px 10px;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
.user{
  margin: auto 0px;
  .name{
    font-family: Rubik-Medium;
    font-size: 16px;
    line-height: 20px;
    color:#0D4270;
    display: flex;
    .wellness-champions{
      width: 164.17px;
      height: 16px;
      margin-left 5px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .wellness-champions-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/wellnessChampion.png");
      min-width: 164.17px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .remove-user-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/RemoveUser.png");
      width: 241px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  .department{
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color:#9c9c9c;
  }
}
}
.no-data{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#0D4270;
  margin: auto;
  display: flex;
  width:8%;
  justify-content: center;
  text-align: center;
}
.profile-image-card{
  display: flex;
  margin: auto;
  margin-left: 12px;
  >img{
    width: 34px;
    height: 34px;
    margin: 10px 0px;
    border-radius: 50%;
    margin-left: -12px;
    border: 2px solid white;
  }
  .count{
    display: 100%;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    font-family: Rubik;
    font-size: 12px;
    color: #fff;
    padding: 0px 8px;
    height: 34px;
    margin: auto;
    border-radius: 15px;
    margin-left: -12px;
    align-items: center;
    text-align: center;
    display: flex;
    border: 2px solid white;
  }
}
.wellness-card{
  display: flex;
  justify-content: center;
  margin: auto;
  margin-left: 0px;
  min-width: 180px;
  justify-content: flex-start;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 65px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }


  }}`;





// width: 100%;
// display: flex;
// ${'' /* height: 80px; */}
// height: ${({inactiveUser}) => inactiveUser ? '80px' :''};
// background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
// margin-bottom: 10px;
// border: ${({active}) => active ? 'none' : 'none'};
// ${'' /* border-radius: 4px; */}
// border-radius: ${({inactiveUser}) => inactiveUser ? '4px' :'6px'};
// &:hover, &:focus, &:active {
//   filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
//   border: ${({deleted}) => deleted ? "1px solid #FD7175" : '1px solid rgba(0, 47, 71, 1)'};
// }
// .rowContent{
//   display: flex;
//   align-items: center;
//   height:80px;
//   width: 100%;
// }
// .wrapper{
//   min-width: 25%;
//   border-radius: ${({inactiveUser}) => inactiveUser ? '4px' : ''};
//   display: flex;
// .profile{
//   width: 60px;
//   height: 60px;
//   margin: 10px 15px 10px 10px;
//   >img{
//     width: 100%;
//     height: 100%;
//     border-radius: 6px;
//   }
// }
// .user{
//   margin: auto 20px;
//   .name{
//     font-family: Rubik-Medium;
//     font-size: 18px;
//     line-height: 24px;
//     color:#0D4270;
//     display: flex;
//     .wellness-champions{
//       width: 164.17px;
//       height: 16px;
//       margin-left 5px;
//       >img{
//         width: 100%;
//         height: 100%;
//       }
//     }
//     .wellness-champions-text{
//       font-family: Rubik;
//       font-size: 14px;
//       line-height: 20px;
//       color: #fff;
//       background-image : url("/images/PeoplePage/wellnessChampion.png");
//       min-width: 164.17px;
//       height: 16px;
//       margin-left: 5px;
//       background-repeat: no-repeat;
//       background-size: 100% 100%;
//       justify-content: center;
//       align-items: center;
//       display: flex;
//     }
//     .remove-user-text{
//       font-family: Rubik;
//       font-size: 14px;
//       line-height: 20px;
//       color: #fff;
//       background-image : url("/images/PeoplePage/RemoveUser.png");
//       width: 241px;
//       height: 16px;
//       margin-left: 5px;
//       background-repeat: no-repeat;
//       background-size: 100% 100%;
//       justify-content: center;
//       align-items: center;
//       display: flex;
//     }
//   }

//   .department{
//     font-family: Rubik;
//     font-size: 14px;
//     line-height: 20px;
//     color:#9c9c9c;
//   }
  
// }
// }

// .email{
//     font-family: Rubik;
//     font-size: 16px;
//     line-height: 20px;
//     color:#0D4270;
//     ${'' /* margin: auto; */}
//     display: flex;
//     min-width:20%;
//     justify-content: left;
//     text-align: left;
//   }
// .reminderText{
//   width: 170px;
//   height: 40px;
//   padding: 8px 20px 8px 20px;
//   border-radius: 4px;
//   opacity: 0px;
//   background:#76AB78;
//   display: flex;
//   min-width:15%;
//   justify-content: left;
//   text-align: left;
//   ${'' /* margin: auto; */}
// }

// .no-data{
//   font-family: Rubik;
//   font-size: 16px;
//   line-height: 20px;
//   color:#005C87;
//   font-weight:200;
//   ${'' /* margin: auto; */}
//   display: flex;
//   min-width:20%;
//   ${'' /* min-width: */}
//   justify-content: left;
//   text-align: left;
// }
// .profile-image-card{
//   display: flex;
//   margin: auto;
//   margin-left: 12px;
//   >img{
//     width: 34px;
//     height: 34px;
//     margin: 10px 0px;
//     border-radius: 50%;
//     margin-left: -12px;
//     border: 2px solid white;
//   }
//   .count{
//     display: 100%;
//     background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
//     font-family: Rubik;
//     font-size: 12px;
//     color: #fff;
//     padding: 0px 8px;
//     height: 34px;
//     margin: auto;
//     border-radius: 15px;
//     margin-left: -12px;
//     align-items: center;
//     text-align: center;
//     display: flex;
//     border: 2px solid white;
//   }
// }
// .wellness-card{
//   display: flex;
//   justify-content: center;
//   margin: auto;
//   margin-left: 0px;
//   min-width: 180px;
//   justify-content: flex-start;
//   >div{
//     width: 32px;
//     height: 32px;
//     display: flex;
//     margin-right: 5px;
//     background: #005C87;
//     border-radius: 6px;
//     >img{
//       width: 20px;
//       height: 20px;
//       margin: auto;
//     }

//     .hover-card{
//       width: 0px;
//       height: 0px;
//       position: relative;
//       display: block;
//       >div{
//         min-width: 65px;
//         max-width: 120px;
//         min-height: 25px;
//         position: relative;
//         right: 50px;
//         bottom: 27px;
//         background: rgba(156, 156, 156, 0.79);

//         font-family: rubik;
//         font-size: 12px;
//         line-height: 12px;
//         color: white;
//         display: flex;
//         justify-content: center;
//         text-align: center;
//         align-items: center;
//         border-radius: 6px;
//         padding: 5px 7px;
//       }
//     }


//   }
// }
// `;

const SendRemainderButton = styled.div`
display: flex;
width: 150px;
height: 40px;
Cursor: pointer;
${'' /* background: red; */}
background: ${({disabled}) => disabled ? 'rgba(0, 122, 177, 0.30)' :'#007AB1'};
border-radius: 4px;
font-family: Rubik-Medium;
font-size: 16px;
line-height: 18px;
color: #ffff;
justify-content: center;
align-items: center;
margin: 15px 0px;
padding: 4px 20px 4px 20px;



&:hover {
  background:${({disabled}) => disabled ? '' :'#005C87'};
  color:#ffff
  }



}
`;

const DeleteUserButton = styled.div`
display: flex;
width: 150px;
height: 40px;
Cursor: pointer;
margin-left: 20px !important;
border: ${({disabled}) => disabled ? '1px solid rgba(0, 122, 177, 0.30)' :'1px solid #007AB1'};
border-radius: 4px;
font-family: Rubik-Medium;
font-size: 16px;
line-height: 18px;
color: ${({disabled}) => disabled ? 'rgba(0, 122, 177, 0.30)' :'#007AB1'};
justify-content: center;
align-items: center;
margin: 15px 0px;
padding: 4px 20px 4px 20px;
}
`;


const ControlButtonsBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .select-page {
  margin-left: 7px;
  font-size: 16px;
  color: #005C87;
}

.select-page:hover {
  color: #005C87; 
  font-weight:bold;
}

}
`;


const ButtonsContainer = styled.div`
  display: flex;
  flex-grow: 1; 
  justify-content: flex-end; 
  align-items: center; 
`;

const HoverableDiv = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid #007AB1;
  border-radius: 21px;
  display: flex;
 

  &:hover {
    border: 2px solid #005C87;
  }
`;

const ButtonAndIcon = styled.div`
    display: flex;
    border-radius: 3px;
    width: 80px;
    align-items: center;
    margin: 15px 0px;
    justify-content: end;
  > img {
    cursor: pointer;
    width: 40px;
  }
  `;

const RowCardInactiveUsers = styled.div`
width: 100%;
display: flex;
width: 399px;
border-radius: 6px;
background: rgb(255, 255, 255);
margin-bottom: 25px;
border: 1px solid #d7d7d7;
background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
margin-bottom: 0px;
cursor: pointer;
border-radius: 6px;
&:hover, &:focus, &:active {
  filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
}
.wrapper{
  width: 553px;
  display: flex;
.profile{
  width: 60px;
  height: 60px;
  margin: 10px 15px 10px 10px;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
.user{
  margin: auto 0px;
  .name{
    font-family: Rubik-Medium;
    font-size: 16px;
    line-height: 20px;
    color:#002F47;
    display: flex;
    .wellness-champions{
      width: 164.17px;
      height: 16px;
      margin-left 5px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .wellness-champions-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/wellnessChampion.png");
      min-width: 164.17px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .remove-user-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/RemoveUser.png");
      width: 241px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  .department{
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color:#9c9c9c;
  }
}
}
.no-data{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#002F47;
  margin: auto;
  display: flex;
  margin-left: 0px;
}
.profile-image-card{
  display: flex;
  margin: auto;
  margin-left: 12px;
  >img{
    width: 34px;
    height: 34px;
    margin: 10px 0px;
    border-radius: 50%;
    margin-left: -12px;
    border: 2px solid white;
  }
  .count{
    display: 100%;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    font-family: Rubik;
    font-size: 12px;
    color: #fff;
    padding: 0px 8px;
    height: 34px;
    margin: auto;
    border-radius: 15px;
    margin-left: -12px;
    align-items: center;
    text-align: center;
    display: flex;
    border: 2px solid white;
  }
}
.wellness-card{
  display: flex;
  justify-content: center;
  margin: auto;
  margin-left: 0px;
  min-width: 180px;
  justify-content: flex-start;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 65px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }


  }
}
`;



//people page updates
const RowCardPeopleView = styled.div`
width: 100%;
display: flex;
${'' /* height: 80px; */}
height: ${({inactiveUser}) => inactiveUser ? '80px' :''};
background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
margin-bottom: 10px;
border: ${({active}) => active ? 'none' : 'none'};
${'' /* border-radius: 4px; */}
border-radius: ${({inactiveUser}) => inactiveUser ? '4px' :'6px'};
&:hover, &:focus, &:active {
  filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
  border: ${({deleted}) => deleted ? "1px solid #FD7175" : ''};
}
.rowContent{
  display: flex;
  align-items: center;
  height:80px;
  width: 100%;
}
.wrapper{
  min-width: 25%;
  border-radius: ${({inactiveUser}) => inactiveUser ? '4px' : ''};
  display: flex;
  padding:10px;
.profile{
  width: 60px;
  height: 60px;
  margin: 10px 15px 10px 10px;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
.user{
  margin: auto 20px;
  .name{
    font-family: Rubik;
    font-weight:bold;
    font-size: 18px;
    line-height: 24px;
    color: #005C87;
    text-transform:capitalize;
    display: flex;
    .wellness-champions{
      width: 164.17px;
      height: 16px;
      margin-left 5px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .wellness-champions-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/wellnessChampion.png");
      min-width: 164.17px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .remove-user-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/RemoveUser.png");
      width: 241px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

  .department{
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color:#9c9c9c;
  }
  
}
}

.email{
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color:#0D4270;
    ${'' /* margin: auto; */}
    display: flex;
    ${'' /* min-width:20%; */}
    min-width: ${({ isShowDepartment, isShowLocation }) => 
    isShowDepartment && isShowLocation ? '30%' : '26%'};
    justify-content: left;
    text-align: left;
  }
.reminderText{
  width: 170px;
  height: 40px;
  padding: 8px 20px 8px 20px;
  border-radius: 24px;
  opacity: 0px;
  background:#76AB78;
  display: flex;
  ${'' /* min-width:15%; */}
  justify-content: left;
  text-align: left;
  ${'' /* margin: auto; */}
}

.no-data{
  font-family: Rubik;
  font-size: 16px;
  line-height: 20px;
  color:#005C87;
  font-weight:200;
  padding-left:5px;
  ${'' /* margin: auto; */}
  display: flex;
  min-width:17%;
  ${'' /* min-width: */}
  justify-content: left;
  text-align: left;
}
.profile-image-card{
  display: flex;
  margin: auto;
  margin-left: 12px;
  >img{
    width: 34px;
    height: 34px;
    margin: 10px 0px;
    border-radius: 50%;
    margin-left: -12px;
    border: 2px solid white;
  }
  .count{
    display: 100%;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    font-family: Rubik;
    font-size: 12px;
    color: #fff;
    padding: 0px 8px;
    height: 34px;
    margin: auto;
    border-radius: 15px;
    margin-left: -12px;
    align-items: center;
    text-align: center;
    display: flex;
    border: 2px solid white;
  }
}
.wellness-card{
  display: flex;
  justify-content: center;
  margin: auto;
  margin-left: 0px;
  min-width: 180px;
  justify-content: flex-start;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: #005C87;
    border-radius: 6px;
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 65px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }


  }
}

`;


const RowCardPeopleAdmin = styled.div`
width: 100%;
height:80px;
display: flex;
background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
margin-bottom: 15px;
align-items:center;
border: ${({active}) => active ? 'none' : 'none'};
border-radius: 6px;
&:hover, &:focus, &:active {
  filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
  border: ${({deleted}) => deleted ? "1px solid #FD7175" : ''};
}
.wrapper{
  min-width: 30%;
  display: flex;
.profile{
  width: 48px;
  height: 48px;
  margin: 10px 15px 10px 10px;
  >img{
    width: 100%;
    height: 100%;
    border-radius: 48px;
  }
}
.user{
  margin: auto 0px;
  ${'' /* min-width: 20%; */}
  .name{
    font-family: Rubik;
    font-weight:bold;
    font-size: 18px;
    line-height: 20px;
    color: #005C87;
    min-width:'25%';
    text-transform:capitalize;
    ${'' /* display: flex; */}
    .wellness-champions{
      width: 164.17px;
      height: 16px;
      margin-left 5px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .wellness-champions-text{
      color: var(--nutrition, #76AB78);
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 20px; 
      margin-top:10px;
    }
    .remove-user-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-image : url("/images/PeoplePage/RemoveUser.png");
      width: 241px;
      height: 16px;
      margin-left: 5px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
   
  }
  }
  
}
.department{
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color:#005C87;
    min-width:20%;
    text-align:left;
    padding-left:5px;
    
    }

.no-data{
  font-family: Rubik;
  font-size: 16px;
  line-height: 20px;
  color:#0D4270;
  ${'' /* display: flex; */}
  min-width:20%;
  ${'' /* justify-content: center; */}
  text-align: left;
}
.profile-image-card{
  display: flex;
  margin: auto;
  margin-left: 12px;
  >img{
    width: 34px;
    height: 34px;
    margin: 10px 0px;
    border-radius: 50%;
    margin-left: -12px;
    border: 2px solid white;
  }
  .count{
    display: 100%;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    font-family: Rubik;
    font-size: 12px;
    color: #fff;
    padding: 0px 8px;
    height: 34px;
    margin: auto;
    border-radius: 15px;
    margin-left: -12px;
    align-items: center;
    text-align: center;
    display: flex;
    border: 2px solid white;
  }
}
.wellness-card{
  display: flex;
  justify-content: center;
  margin: auto;
  margin-left: 0px;
  min-width: 20%;
  justify-content: flex-start;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: #005C87;
    border-radius: 6px;
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }

    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 65px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }


  }}
  .viewListProfile{
  display: flex;
  min-width:10%;
 >div{
  width: 40px;
  height: 40px;
  justify-content: center;
  ${'' /* align-items: center; */}
  flex-shrink: 0;
  border-radius:4px;
 }
}
  
  `;



export { Layout, RowCardInactiveUsers, HoverableDiv, ButtonsContainer, RowCardPeopleView,DeleteUserButton, RowCardPeopleAdmin, HeaderSection, NameSection,RowCardPeopleHeader, OtherSections, ImageContainer, EmployeeName, Designation, Row, EmployeeInterestContainer, NameSectionNew, MutualHeading, RowCard, RowCardPeople, SendRemainderButton, ControlButtonsBar, ButtonAndIcon};